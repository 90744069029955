<template>
  <section class="ct-banner-feed">
    <div class="ct-banner-feed__container container">
      <div class="content-type__content">
        <div
          v-if="slice?.Settings?.Title"
          class="content-type__heading heading heading--2"
        >
          {{ slice.Settings.Title }}
        </div>

        <p
          v-if="slice?.Settings?.Description"
          class="content-type__description"
        >
          {{ slice.Settings.Description }}
        </p>

        <VueCarousel
          v-if="slice?.FeedDisplay?.Display === 'Slider'"
          v-bind="sliderSettings"
          ref="carousel"
        >
          <VueSlide
            v-for="banner in slice?.Banners"
            :key="banner"
          >
            <StrapiBanner
              :banner="banner"
              :lazy="index > 0"
              :banner-count="slice?.Banners?.length"
              :full-width="slice?.Settings?.SliceWidth?.toLowerCase() === 'full_width'"
              class="ct-banner-feed__banner"
            />
          </VueSlide>

          <template #addons>
            <div
              v-if="showNavigation"
              class="carousel__navigation"
            >
              <VueNavigation>
                <template #prev>
                  <IconArrow class="carousel__icon icon icon--arrow icon--arrow-left" />
                </template>
                <template #next>
                  <IconArrow class="carousel__icon icon icon--arrow icon--arrow-right" />
                </template>
              </VueNavigation>
            </div>
          </template>
        </VueCarousel>

        <div
          v-else-if="slice?.FeedDisplay?.Display === 'Grid'"
          class="ct-banner-feed__grid"
          :style="`--columns: ${slice?.Banners?.length};`"
        >
          <StrapiBanner
            v-for="banner in slice?.Banners"
            :key="banner"
            :banner="banner"
            :lazy="index > 0"
            :banner-count="slice?.Banners?.length"
            :full-width="slice?.Settings?.SliceWidth?.toLowerCase() === 'full_width'"
            :class="['ct-banner-feed__banner', { 'banner--hero': slice?.Banners?.length === 1 && slice?.Settings?.SliceWidth?.toLowerCase() === 'full_width' }]"
          />
        </div>
      </div>

      <!-- <DevOnly v-if="slice?.FeedDisplay?.Display === 'Slider'"><pre>sliderSettings: {{ sliderSettings }}</pre></DevOnly> -->
      <!--  -->
    </div>
  </section>
</template>

<script setup lang="ts">
const props = defineProps({
  slice: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  slices: {
    type: Array,
    required: true,
  },
})

const { settings } = useSliderSettings()
const sliderSettings = Object.assign({}, settings, {
  itemsToShow: 1.25,
  itemsToScroll: 1,
  breakpoints: {
    700: {
      itemsToShow: 2,
      itemsToScroll: 2,
    },
    1024: {
      itemsToShow: 3,
      itemsToScroll: 3,
    },
  },
})

const carousel = ref()
const showNavigation = computed(() => {
  return props.slice?.Banners?.length > carousel.value?.data?.config?.itemsToShow
  // return carousel.value?.data?.slidesCount.value > carousel.value?.data?.config?.itemsToShow
})
</script>

<style src="~layers/app/assets/css/vue3-carousel-nuxt.css" />

<style src="~layers/app/components/Slices/BannerFeed.css" />
